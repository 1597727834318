<template>
  <section v-if="showmenu">
    <div v-if="!fetching && $auth.ready()">
      <div class="columns">
        <h1 class="title">FAST subject headings</h1>
      </div>
      <div class="columns">
        <div class="column">
          <div class="content has-text-left">
            <ol>
              <li v-bind:key="index" v-for="(subject, index) in fasts" class="is-size-5">
                <router-link :to="'/fast/' + subject.id ">
                  {{subject.attributes.name }}
                </router-link>  <span class="tag section_count"> {{ subject.attributes.books_count }} books</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <img src="@/assets/ajax-loader.gif"/>
    </div>
  </section>

</template>
<script>
export default {
  data () {
    return {
      fetching: true,
      showmenu: true,
      fasts: []
    }
  },
  mounted () {
    this.$attrs.searchTerm = null
    this.axios.get('/v1/fasts')
    .then((response) => {
      this.fasts = response.data.data
      this.fetching = false
    })
  }
}
</script>